import i18n from 'i18next';
import { initReactI18next, setI18n } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

import slo from './locales/sl/translation.json';
import sloZod from './locales/sl/zod.json';
import skoki from './locales/sl/skoki.json';

export const sl = { kolesar: slo, zod: sloZod, skoki };

export const i18nResources = {
	sl,
};

const init = () => {
	const initOptions: any = {
		compatibilityJSON: 'v3',
		lng: 'sl',
		debug: false, // import.meta.env.VITE_LOCAL
		fallbackLng: 'sl',
		defaultNS: "kolesar",
		saveMissing: false, // to add new key and value - {t('new.key', 'value for this key')}
		react: {
			useSuspense: false,
		},
	};

	i18n.options.react = initOptions.react;
	setI18n(i18n);

	i18n.use(initReactI18next);
	initOptions.resources = i18nResources;
	z.setErrorMap(zodI18nMap);
	i18n.init(initOptions);
};

init();

export default i18n;
